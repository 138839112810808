import {api} from "@/http/http.api";
import {handleTree} from "@/utils/validate";
import processSubjectTree from "@/utils/processSubjectTree";

export default {

    namespaced: true,

    state: {
        brandList: [],//品牌列表
        attrList:[],//属性列表
        prodType:[],//干冻品
        weightUnit:[],//重量单位
        bcList:[]
    },

    mutations: {
        getBrandList(state, data) {
            state.brandList = data
        },
        getAttrList(state,data){
            state.attrList=data
        },
        getProdType(state,data){
            state.prodType=data
        },
        getWeightUnit(state,data){
            state.weightUnit=data
        },
        getBcList(state,data){
            state.bcList=data
        },
    },

    actions: {
        //仓库列表
        getBrandList(ctx) {
            api.brandAll().then(res => {
                ctx.commit('getBrandList', res.data.data)
            })
        },
        //属性列表
        getAttrList(ctx){
            api.getGoodsAttribute().then(res => {
                ctx.commit('getAttrList', res.data.data)
            })
        },
        //干冻品
        getProdType(ctx){
            api.getDictionary('prod_type').then(res => {
                ctx.commit('getProdType',res.data.data)
            })
        },
        //重量
        getWeightUnit(ctx){
            api.getDictionary('weight_unit').then(res => {
                ctx.commit('getWeightUnit',res.data.data)
            })
        },
        getBcList(ctx){
            api.bcListAll().then(res=>{
                let bcList = handleTree(res.data.data, "value", "bcParentId")
                processSubjectTree(bcList)
                ctx.commit('getBcList',bcList)
            })
        }
    }

}
