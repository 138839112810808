const processSubjectTree = tree => {
    tree.forEach(branch => {
        if (branch.children && branch.children.length) {
            processSubjectTree(branch.children);
        } else {
            branch.children = null;
        }
    });
};

export default processSubjectTree
