// 库存管理模块api
import http from "../http.interceptors";

const courseApi = {
  /*
        课程管理
     */
  // 获取课程管理列表
  getCourseList: params => http.get('/api/educourse/page', { params }),
  // 添加/编辑课程
  courseHandle: (url, params, type) => type == 'add' ? http.post(url, params) : http.put(url, params),
  // 删除课程
  delCourse: (params) => http.put("/api/educourse/del", params),
  // 获取课程详情
  getCourseInfo: id => http.get("/api/educourse/" + id),
  // 内容设置请求
  contentHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'list':
        fun = http.get(url, { params });
        break;
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'move':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },

  // 获取课程的介绍内容详情
  getCourseIntroInfo: params => http.get('/api/course/intro/content/list', { params }),
  // 课程介绍附件请求
  introContentFileHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程介绍图文请求
  introContentHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程介绍视频请求
  introVideoHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },

  // 获取课程内容列表
  getCourseContentList: params => http.get('/api/educoursetheme/page', { params }),
  // 课程内容添加/编辑/删除请求
  courseContentHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 根据id查询课程内容基本信息
  getCourseContentInfo: id => http.get('/api/educoursetheme/' + id),
  // 获取课程内容目录列表
  getCourseContentCataLogList: params => http.get('/api/educoursethemecatalogue/tree/list', { params }),
  // 课程内容目录添加/编辑/删除请求
  courseContentCataLogHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
      case 'move':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程内容的指标设置请求
  contentTargetHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'list':
        fun = http.get(url, { params });
        break;
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'move':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.delete(url + params);
        break;
    }

    return fun;
  },

  // 获取课程内容目录内容详情
  getCourseContentCatalogInfo: params => http.get('/api/educoursethemecatalogue/list/detail', { params }),
  // 课程内容的目录内容的附件请求
  contentCatalogContentFileHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程内容的目录内容的图文请求
  contentCatalogContentHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程内容的目录内容的视频请求
  contentCatalogVideoHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 课程内容的目录内容的活动请求
  contentCatalogActivityHandle: (url, params, type) => {
    let fun = null;
    switch (type) {
      case 'list':
        fun = http.get(url, { params });
        break;
      case 'add':
        fun = http.post(url, params);
        break;
      case 'edit':
        fun = http.put(url, params);
        break;
      case 'del':
        fun = http.put(url, params);
        break;
    }

    return fun;
  },
  // 获取指标库下拉
  getTargetArmOption: params => http.get('/api/edutargetguide/list', { params }),
  // 根据指标库id获取目标下拉
  getTargetOption: id => http.get('/api/edutargetinfo/getEduTargetInfoByGuideId?guideId=' + id),
  // 根据目标维度以及维度字典值获取指标内容下拉
  getTargetContentOption: params => http.get('/api/edutargetdescription/getListByTargetInfo', { params }),

  // 获取目标的教学建议
  getTargetInfoDetailByCatalogueId: params => http.get('/api/course/getTargetInfoDetailByCatalogueId', { params }),
  // 获取目标的教学实践标签list
  getTargetInfoByDict: params => http.get('/api/course/getTargetInfoByDict', { params }),
  // 获取目标的教学实践标签下的实践信息
  getTargetInfoByPracticeTag: params => http.get('/api/course/getTargetInfoByPracticeTag', { params }),

  // 获取推送记录列表
  getPublishPage: params => http.get('/api/techcoursepublishinfo/page', { params }),
  // 获取推送记录的筛选课程列表
  getPublishCourse: params => http.get('/api/techcoursepublishinfo/getPublishCourse', { params }),
  // 获取推送的学生记录列表
  getPublishStudentList: params => http.get('/api/techcoursepublishinfo/getPublishStudentList', { params }),
  // 获取推送记录详情
  getPublishInfo: id => http.get('/api/techcoursepublishinfo/getPublishInfoDetail', { params: { id: id } }),
  // 获取推送记录的编辑详情
  getEditPublishInfo: params => http.get('/api/techcoursepublishinfo/push/detail', { params }),
  // 获取班级推送信息
  getPublishClassList: params => http.get('/api/techcoursepublishinfouser/class/count', { params }),
  // 获取班级下的查看、提交、未提交、点赞、回复数量信息
  getPublishClassAllCountList: params => http.get('/api/techcoursepublishinfo/getClassStuByClassId', { params }),
  // 获取评论信息
  getPublishClassCommentList: params => http.get('/api/techcomment/page', { params }),
  // 获取评论的附件信息
  getPublishClassCommentFileList: params => http.get('/api/techcommentfile/list', { params }),
  // 获取评论的点赞数和回复数信息
  getPublishClassCommentCountInfo: params => http.get('/api/techcommentlike/count', { params }),
  // 获取登录人是否对评论点赞过信息
  getPublishClassCommentIsLink: params => http.get('/api/techcommentlike/isLike', { params }),
  // 评论/回复的点赞处理
  commentLinkHandle: (url, params, type) => {
    if (type == 'add') {
      return http.post(url, params);
    } else if (type == 'del') {
      return http.put(url, params);
    }
  },
  // 回复提交
  commentSubmit: params => http.post('/api/techcomment', params),

  // 获取课程资源列表
  getCourseResourceList: params => http.get('/api/techusercourse/course/list', { params }),
  // 获取条件筛选的课程列表
  getCourseSearchList: params => http.get('/api/course/list', { params }),
  // 获取某个课程的内容列表
  getCourseThemeList: params => http.get('/api/course/theme/list', { params }),
  // 获取课程内容的目录树列表
  getCourseThemeCatalogTreeList: params => http.get('/api/course/theme/tree/list', { params }),
  // 获取课程内容的目录下的目标清单列表
  getCourseThemeTargetList: params => http.get('/api/course/target/list', { params }),
  // 获取课程内容的目录下的内容信息
  getCourseThemeDetailInfo: params => http.get('/api/course/theme/list/detail', { params }),
  // 添加课程资源关联
  addCourseThemeContact: params => http.post('/api/techusercourse', params),
  // 删除课程资源关联
  delCourseThemeContact: params => http.put('/api/techusercourse/del', params),
  //获取课程介绍内容
  getCourseIntroDetailInfo: params => http.get('/api/course/detail', { params }),

  // 点击课程资源的推送操作
  getCoursePushInfo: params => http.get('/api/course/push', { params }),
  // 编辑推送页的推送
  pushCourse: params => http.post('/api/techcoursepublishinfo', params),
  // 编辑推送页的推送修改（未推送变已推送）
  pushNoSendCourse: params => http.put('/api/techcoursepublishinfo', params),
  // 编辑推送页的取消
  cancelPushCourse: params => http.put('/api/techcoursethemecataloguecontent/cancel/push', params),
  // 推送记录撤销
  changePushCourseStatus: params => http.put('/api/techcoursepublishinfo', params),
  // 查询班级
  getCourseClassList: params => http.get('/api/techcoursepublishinfo/getClassStuByUserId', { params }),
  // 获取推送记录详情-推送编辑页弹窗
  getPushCourseRecordInfo: params => http.get('/api/techcoursepublishinfo/getUpPublishInfoDetail', { params }),

  // 意见反馈提交
  feedbackSave: params => http.post('/api/techfeedback', params),
};

export { courseApi };
