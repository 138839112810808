/*
    资源管理路由
 */
    export default [
      {
        path: '/resource',
        meta: {
          title: '资源管理'
        },
        component: () => import('@/layout/Main'),
        hidden: false,
        children: [
          { // 课程资源
            path: '/courseResource',
            name: 'courseResource',
            meta: {
              title: '课程资源'
            },
            hidden: false,
            component: () => import('@/views/resourceManage/courseResource/index.vue')
          },
          { // 课程资源-推送编辑
            path: '/courseResource/sendEdit',
            name: 'sendEdit',
            meta: {
              title: '课程资源-推送编辑'
            },
            hidden: true,
            component: () => import('@/views/resourceManage/courseResource/sendEdit.vue')
          },
          { // 课程资源-课程介绍
            path: '/courseResource/courseIntro',
            name: 'courseIntro',
            meta: {
              title: '课程资源-课程介绍'
            },
            hidden: true,
            component: () => import('@/views/resourceManage/courseResource/courseIntro.vue')
          },
          { // 课程资源-课程内容详情
            path: '/courseResource/courseContent',
            name: 'courseContent',
            meta: {
              title: '课程资源-课程内容'
            },
            hidden: true,
            component: () => import('@/views/resourceManage/courseResource/courseContent.vue')
          },
          { // 推送记录
            path: '/sendRecordManage',
            name: 'sendRecordManage',
            meta: {
              title: '推送记录'
            },
            hidden: false,
            component: () => import('@/views/resourceManage/sendRecordManage/index.vue')
          },
          { // 课程资源-推送编辑
            path: '/sendRecordManage/detail',
            name: 'sendRecordDetail',
            meta: {
              title: '推送记录-详情'
            },
            hidden: true,
            component: () => import('@/views/resourceManage/sendRecordManage/detail.vue')
          },
        ]
      }
    ]