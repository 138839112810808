export default [
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      title: '意见反馈'
    },
    hidden: false,
    component: () => import('@/views/feedback/index.vue')
  }
];
