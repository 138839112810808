<template>
  <div class="login-page" :style="note">
    <div class="login-wrap" :style="loginWarp">
      <div class="img-wrap">
        <img src="../../assets/img/login-left-img.png" alt="" />
      </div>
      <div class="login-form-wrap">
        <div class="login-form-center">
          <div class="login-title">
            <!-- <p class="login-title-desc">Integrated management system user login</p> -->
            <p class="login-title-text">青蓝STAR教师平台用户登录</p>
          </div>
          <div class="login-form">
            <div>
              <input class="login-input" placeholder="请输入您的手机号" v-model.trim="loginForm.username" maxlength="20" />
            </div>
            <div>
              <input class="login-input" type="password" placeholder="请输入您的密码" maxlength="20" v-model.trim="loginForm.password" />
            </div>
            <div class="login-code">
              <label>
                <input class="login-input login-validate" placeholder="请输入验证码" v-model.trim="loginForm.code" maxlength="4" />
              </label>
              <div class="login-code-img">
                <img v-if="code.src" :src="code.src" alt="" @click="refreshCode" />
              </div>
            </div>
            <div class="auto-pass">
              <label class="auto-login"> <input type="checkbox" v-model="autoPass" />记住密码 </label>
              <p class="pass-forget" @click="handleForgetPassword">忘记密码?</p>
            </div>
          </div>
          <button class="login-btn" v-loading="buttonLoading" @click="handleLogin">登 录</button>
        </div>
        <img class="right-icon" src="../../assets/img/login-right-icon.png" alt="" />
      </div>
    </div>
    <el-dialog title="重置密码" :visible.sync="$store.state.dialogVisible" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form class="dialog-form" :model="resetPasswordForm" ref="resetPasswordForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="22">
            <el-form-item class="form-choose-item" label="手机号：" prop="phone">
              <el-input class="form-input" v-model="resetPasswordForm.phone" placeholder="请输入账号手机号码" :maxlength="11"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item class="form-choose-item" label="验证码：" prop="authCode">
              <el-input class="auth-code-input" placeholder="请输入短信验证码" v-model="resetPasswordForm.authCode">
                <div slot="suffix" class="auth-button" :class="{ gray: isClick }" @click.stop="handleAuthCode">{{ !isClick ? "获取验证码" : secondNum }}</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item class="form-choose-item" label="新密码：" prop="password">
              <el-input class="form-input" type="password" v-model="resetPasswordForm.password" placeholder="请输入8-16位登录密码" :maxlength="16"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item class="form-choose-item" label="确认密码：" prop="confirmPassword">
              <el-input class="form-input" type="password" v-model="resetPasswordForm.confirmPassword" placeholder="请再次输入新的登录密码" :maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="dialogButtonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="dialogButtonloading" type="primary" @click="handleSubmit">重 置</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="successDialogVisible" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" @before-close="handleClose" @close="handleClose" width="50%">
      <div class="success-body">
        <img class="success-icon" src="../../assets/img/success-dialog-icon.png" alt="" />
        <div class="success-title">密码重置成功</div>
        <div class="success-remark">请妥善保管您的账户信息</div>
        <el-button class="success-button" type="primary" :loading="dialogButtonloading" @click="handleSuccessLogin('click')">进入系统({{ submitNum }})</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import randomLenNum from "@/utils/randomLenNum";
// import encryption from "@/utils/encryption";
import qs from "qs";
import { setToken } from "@/utils/auth";
import asyncRouter from "@/router/asyncRouter";
import initialData from "@/utils/initialData";
import watermark from "@/utils/watermark";
import { validNoContinue, validNoSame } from "@/utils/validate";

export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (!/^1[2-9]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不对！"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      let rules1 = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,16}$/g;
      if (value === "") {
        callback();
      } else {
        if (!rules1.test(value)) {
          callback(new Error("登录密码为8-16位且必须有数字、字母"));
        } else if (!validNoSame(value)) {
          callback(new Error("登录密码不允许3个以上的重复字符"));
        } else if (!validNoContinue(value)) {
          callback(new Error("登录密码不允许3个以上的连续字符"));
        } else {
          if (this.resetPasswordForm.confirmPassword !== "") {
            this.$refs.resetPasswordForm.validateField("confirmPassword");
          }
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (value !== this.resetPasswordForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      note: {
        backgroundImage: "url(" + require("../../assets/img/jy-login-bg.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        marginTop: "0",
      },
      loginWarp: {
        backgroundColor: "#fff",
      },
      code: {
        src: "/code",
        len: 4,
        type: "image",
        key: "",
      },
      randomStr: null,
      loginForm: {
        username: "",
        password: "",
        code: "",
        key: "",
      },
      autoPass: false,
      buttonLoading: false, // 按钮loading

      // resetPasswordVisible: false, // 重置密码弹窗标识// 账号被顶要弹窗、点击确定弹出修改密码。
      resetPasswordForm: {
        phone: "",
        authCode: "",
        password: "",
        confirmPassword: "",
      }, // 重置密码弹窗表单
      rules: {
        phone: [
          { required: true, message: "请输入账号手机号码", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        authCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入8-16位登录密码", trigger: "blur" },
          { min: 8, message: "密码长度不能小于8", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入新的登录密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      }, // 表单规则
      isClick: false, // 是否点击获取验证码按钮
      secondNum: 60, // 秒数
      setIntervalObject: null, // 计时对象
      dialogButtonloading: false, // 弹窗按钮loading
      submitNum: 5, // 进入系统倒计时
      setIntervalResetObject: null, // 密码重置成功计时对象

      successDialogVisible: false, // 密码重置成功
      successForm: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    let _this = this;
    document.onkeydown = function (e) {
      if (e.keyCode === 13) {
        _this.handleLogin(); // 自己写的登录方法，点击事件
      }
    };
  },
  mounted() {
    this.refreshCode();
  },
  methods: {
    handleLogin() {
      const loginForm = this.loginForm;
      if (!loginForm.username) {
        this.$message.warning("请输入用户名");
        return false;
      }
      if (!loginForm.password) {
        this.$message.warning("请输入密码");
        return false;
      }
      if (loginForm.password.length < 6) {
        this.$message.warning("密码长度最小为6位");
        return false;
      }
      if (!loginForm.code) {
        this.$message.warning("请输入验证码");
        return false;
      }

      this.loginByUsername();
    },
    loginByUsername(type, callback) {
      this.buttonLoading = true;
      let loginForm = null;
      if (type == "reset") {
        loginForm = this.successForm;
      } else {
        loginForm = this.loginForm;
      }

      // const user = encryption({
      //   data: loginForm,
      //   key: 'blsframework1.00',
      //   param: ['password']
      // })

      const params = {
        phone: loginForm.username,
        password: loginForm.password,
        key: this.code.key,
        code: loginForm.code,
        // grant_type: 'password',
        // scope: 'server'
      };
      this.$api.loginByUsername(params).then((res) => {
        this.buttonLoading = false;
        console.log(res);
        if (res.data.code === 0) {
          this.$message.success("登录成功");
          setToken(res.data.data.token, this.autoPass);

          watermark.set(res.data.data.username);
          this.$store.commit("getUserInfo", res.data.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.$api.getMenu().then((res) => {
            this.$store.commit("getPermissions", res.data.data);
            asyncRouter();
            initialData();
            if (type != "reset") {
              this.$router.push("/");
            } else {
              callback(true);
            }
          });
        } else {
          callback && callback(false);
          this.$message.error(res.data.msg);
        }
      });
    },
    refreshCode() {
      this.loginForm.code = "";
      this.$api.getAuthCode({}).then((res) => {
        if (res.data.code === 0) {
          this.code.src = res.data.data.image;
          this.code.key = res.data.data.key;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取验证码
    handleAuthCode() {
      this.$refs.resetPasswordForm.validateField("phone", (errorMessage) => {
        if (errorMessage) {
          return;
        }
        if (!this.isClick) {
          this.$api.getVerificationCode({ phone: this.resetPasswordForm.phone }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("验证码发送成功");
              this.isClick = true;
              this.setIntervalObject = setInterval(() => {
                if (this.secondNum === 0) {
                  this.isClick = false;
                  this.$nextTick(() => {
                    this.secondNum = 60;
                    clearInterval(this.setIntervalObject);
                    this.setIntervalObject = null;
                  });
                } else {
                  this.secondNum--;
                }
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    // 忘记密码
    handleForgetPassword() {
      // this.resetPasswordVisible = true;
      this.$store.commit("setModifyPwd", true);
    },
    // 弹窗关闭
    handleClose() {
      // this.resetPasswordVisible = false;
      this.$store.commit("setModifyPwd", false);
      this.$nextTick(() => {
        this.dialogButtonloading = false;
        this.resetPasswordForm = {
          phone: "",
          authCode: "",
          password: "",
          confirmPassword: "",
        };
        this.$refs.resetPasswordForm.clearValidate();
      });
    },
    // 弹窗提交
    handleSubmit() {
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          this.dialogButtonloading = true;
          let params = {
            phone: this.resetPasswordForm.phone,
            code: this.resetPasswordForm.authCode,
            pwd: this.resetPasswordForm.password,
          };
          this.$api.resetPwd(params).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("密码重置成功");
              this.successForm = {
                username: this.resetPasswordForm.phone,
                password: this.resetPasswordForm.password,
              };
              this.loginForm = {
                username: this.resetPasswordForm.phone,
                password: "",
                code: "",
                key: "",
              };
              this.refreshCode();
              this.$nextTick(() => {
                this.handleClose();
                // this.$nextTick(() => {
                //   this.successDialogVisible = true;
                //   this.$nextTick(() => {
                //     this.setIntervalResetObject = setInterval(() => {
                //       if (this.submitNum === 0) {
                //         this.dialogButtonloading = true
                //         clearInterval(this.setIntervalResetObject);
                //         this.handleSuccessLogin();
                //         this.setIntervalResetObject = null;
                //       } else {
                //         this.submitNum--;
                //       }
                //     }, 1000);
                //   });
                // });
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 处理重置密码进入系统
    handleSuccessLogin(type) {
      if (type == "click") {
        this.dialogButtonloading = true;
        clearInterval(this.setIntervalResetObject);
        this.setIntervalResetObject = null;
        this.loginByUsername("reset", (data) => {
          if (!data) {
            return;
          }
          this.successDialogVisible = false;
          this.$nextTick(() => {
            this.dialogButtonloading = false;
            this.submitNum = 5;
            this.$router.push("/");
          });
        });
      } else {
        this.loginByUsername("reset", (data) => {
          if (!data) {
            return;
          }
          this.successDialogVisible = false;
          this.$nextTick(() => {
            this.dialogButtonloading = false;
            this.submitNum = 5;
            this.$router.push("/");
          });
        });
      }
    },
  },
  beforeDestroy() {
    document.onkeydown = null;
    this.isClick = false;
    this.secondNum = 60;
    if (this.setIntervalObject !== null) {
      clearInterval(this.setIntervalObject);
      this.setIntervalObject = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100%;
  // min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-wrap {
    width: 67.708333%;
    height: 69.144%;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 98px rgba(89, 126, 247, 0.3);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    .img-wrap {
      width: 46.154%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .login-form-wrap {
      width: 53.847%;
      height: 100%;
      padding-right: 5.847%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      .login-form-center {
        width: 71.156%;
      }
      .right-icon {
        position: absolute;
        top: 0;
        right: 5.716%;
        width: 6.859%;
      }
      .login-title {
        margin-bottom: 13.74%;

        .login-title-desc {
          font-size: 0.5em;
          line-height: 1em;
          letter-spacing: -1px;
          color: #a3a3a3;
        }

        .login-title-text {
          font-size: 1.8vw;
          line-height: 1.4;
          color: #597ef7;
          font-weight: bold;
          padding-left: 1.805%;
        }
      }

      .login-form {
        label {
          display: block;
          width: 65%;
        }
        .login-input {
          display: block;
          width: 100%;
          height: 2.6049vw;
          border-radius: 32px;
          outline: none;
          border: none;
          box-sizing: border-box;
          padding-left: 6.76%;
          background-color: #f6f7fb !important;
          color: #7d7d7d;
          font-size: 0.8em;
          margin-bottom: 6.76%;
        }

        .login-user {
          background-image: url("../../assets/img/login-user.png");
        }

        .login-pass {
          background-image: url("../../assets/img/login-pass.png");
        }

        // .login-code input {
        //   background: url("../../assets/img/iconyanzhengma.png") no-repeat 20px
        //     center;
        // }

        .login-validate {
          width: 100%;
        }

        .auto-pass {
          display: flex;
          justify-content: space-between;
          margin-top: 4.307692%;

          .pass-forget {
            cursor: pointer;
            color: #597ef7;
            font-size: 0.9em;
            line-height: 1;
          }

          .auto-login {
            line-height: 1;
            color: rgba(0, 0, 0, 0.65);
            font-size: 0.9em;
          }
        }
      }

      .login-code {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .login-code-img {
          height: 2.6049vw;
          img {
            height: 100%;
          }
        }
      }

      .login-btn {
        width: 100%;
        height: 3.334vw;
        box-sizing: border-box;
        border: none;
        color: #fff;
        font-size: 1.1em;
        border-radius: 32px;
        margin-top: 13.517%;
        outline: none;
        cursor: pointer;
        background: linear-gradient(180deg, #597ef7 0%, #6372f9 99%, #6174f9 100%);
        box-shadow: 2px 8px 9px rgba(138, 138, 138, 0.12);
      }
    }
  }
}
.auth-code-input {
  /deep/.el-input__suffix {
    right: 0;
  }
  .auth-button {
    width: 95px;
    height: 100%;
    background-color: #597ef7;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &.gray {
      background-color: #a3a3a3;
      color: #7d7d7d;
      cursor: none;
    }
  }
}
.success-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
  }
  .success-title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 11px;
  }
  .success-remark {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 42px;
  }
}
</style>
