import http from '../http.interceptors';

const teachingProgram = {
  // 上传图片
  uploadImage: params => http.post('/api/upload/uploadImgToOSS', params),
  // 获取活动类型 - 一日流程
  getActivityDict: params => http.get('/api/plantokmaser/getActivityDict', { params }),
  // 一日流程列表
  searchLinkList: params => http.get('/api/techdayconfig/search', { params }),
  // 一日流程列表（不分页 选择框
  searchLinkCheckList: params => http.get('/api/techdayconfig/search', { params }),
  // 添加一日流程模版
  insertLink: params => http.post('/api/techdayconfig/save', params),
  // 一日流程删除
  deleteDayLink: id => http.delete('/api/techdayconfig/' + id),
  // 修改一日流程模版
  updateLink: params => http.post('/api/techdayconfig/updateDayConfig', params),
  // 一日详情
  searchTemplateDetails: id => http.get('/api/techdayconfig/' + id),
  // 周计划模板列表
  searchWeekTemplateList: params => http.get('/api/techplantemplate/search', { params }),
  // 周计划模版详情
  searchWeekTemplate: id => http.get('/api/techplantemplate/' + id),
  // 修改周计划模版
  updateWeekTemplate: params => http.post('/api/techplantemplate/updateById', params),
  // 添加周计划模版
  insertWeekTemplate: params => http.post('/api/techplantemplate/save', params),
  // 删除周计划模版
  deleteWeekTemplate: id => http.delete('/api/techplantemplate/' + id),
  // 更改周计划模版适用班级
  updateTemplateClass: params => http.post('/api/techplantemplate/updateClasses', params),
  //获取登录人是否为园长以及园长身份下的学校信息
  getSchoolInfo: params => http.get('/api/techplantemplate/getMyInfo', { params }),
  //获取学校下的年级列表信息
  getGradeInfo: params => http.get('/api/techplantemplate/getGradeListBySchool', { params }),
  //获取年级下的班级列表信息
  getClassInfo: params => http.get('/api/techplantemplate/getClassesListByGrade', { params }),
  // 获取学生班级
  searchStudentsClass: params => http.get('/api/techplantemplate/getMyClasses', { params }),
  // 根据班级获取学生
  searchStudents: params => http.get('/api/techplanteachstudent/student/info', { params }),
  // 选择资源-课程搜索
  searchCourse: params => http.get('/api/course/user/new/list', { params }),
  // 选择资源-课程搜索-课程内容
  searchCourseContent: params => http.get('/api/course/theme/list', { params }),
  // 选择资源-课程搜索-课程内容-活动列表
  searchCourseContentActivityList: params => http.get('/api/course/theme/activity/list', { params }),
  // 选择资源-课程搜索-课程内容-活动列表-集体活动
  searchCourseContentActivityCatalogueList: params => http.get('/api/course/theme/catalogue/list', { params }),
  // 选择资源-课程搜索-活动详情
  searchCourseContentActivityDetail: params => http.get('/api/course/activity/detail', { params }),
  // 选择资源-课程搜索-活动详情-集体活动
  searchCourseContentCatalogueActivityDetail: params => http.get('/api/course/theme/list/detail', { params }),
  // 教学计划
  searchTeachingPrograms: params => http.get('/api/teaching/program', { params }),
  //教学计划-推送状态
  searchTeachingProgramsStatus: params => http.get('/api/techplan/getPlanIsExist', { params }),
  // 教学计划-保存/修改
  updateTeachingPrograms: params => http.post('/api/techplandata', params),
  // 教学计划-根据班级，查询模版
  searchTeachingProgramsGradeTemplate: params => http.get('/api/techplan/getTechTemplateList', { params }),
  // 教学计划-根据班级-模版-查询详情
  searchTeachingProgramsGradeTemplateDetails: params => http.get('/api/techplan/list', { params }),
  // 教学计划-数据重置
  resetTeachingPrograms: params => http.get('/api/techplan/reset', { params }),
  //教学计划-切换模板
  changeTeachingPrograms: params => http.post('/api/techplandata/reset', params),
  // 教学计划-推送
  pushTeachingPrograms: params => http.post('/api/techplandata/push', params),
  // 教学计划-推送记录
  pushRecordTeachingPrograms: params => http.post('/api/techplandata/', params),
  // 查询最近五条活动名称
  searchTeachingHistoryList: params => http.get('/api/techplandata/list', { params }),
  // 查询推送记录
  searchPushRecord: params => http.get('/api/techplandata/getPushPlan', { params }),
  //删除教学计划的活动详情
  delPlanDetail: params => http.put('/api/techplandata/del', params),
};

export default teachingProgram;
