import {api} from "@/http/http.api";

export default {

    namespaced: true,

    state: {
        baserList: [],//仓库列表
        supplierList: [],//供应商列表
        deliveryStatus: [],//发货状态
        reviewStatus: [],//审核状态
        paymentStatus: [],//付款状态
        shipTypeList: [],//出货类型
        shippingList: [],//配送方式
        payMethodList: [],//付款方式
        lineList: [],//配送线路
        settlementList:[],//结算方式
        invoiceTypeList:[],//发票类型
        shareList: [
            {value: '0', label: '按金额分摊'},
            {value: '1', label: '按数量分摊'}
        ],//分摊方式
        billLevelList:[], // 单据级别
        transferReason:[],//调库原因
        isSelect: false, // 整个项目能否进行鼠标选中
    },

    mutations: {
        getBaserList(state, data) {
            state.baserList = data
        },
        getSupplier(state, data) {
            state.supplierList = data
        },
        getDeliveryStatus(state, data) {
            state.deliveryStatus = data
        },
        getTransferReason(state, data) {
            state.transferReason = data
        },
        getReviewStatus(state, data) {
            state.reviewStatus = data
        },
        getPaymentStatus(state, data) {
            state.paymentStatus = data
        },
        getAllShipType(state, data) {
            state.shipTypeList = data
        },
        getAllShipping(state, data) {
            state.shippingList = data
        },
        getAllPayment(state, data) {
            state.payMethodList = data
        },
        getAllLine(state, data) {
            state.lineList = data
        },
        getSettlement(state,data){
            state.settlementList=data
        },
        getInvoiceType(state,data){
            state.invoiceTypeList=data
        },
        getLevelOrder(state,data){
            state.billLevelList=data
        },
    },

    actions: {
        //仓库列表
        getBaserList(ctx) {
            api.getAllBaser({}).then(res => {
                ctx.commit('getBaserList', res.data.data)
            })
        },
        //供应商列表
        getSupplier(ctx) {
            api.getAllSupplier({}).then(res => {
                ctx.commit('getSupplier', res.data.data)
            })
        },
        //发货状态
        getDeliveryStatus(ctx) {
            api.getDictionary('delivery_status').then(res => {
                ctx.commit('getDeliveryStatus', res.data.data)
            })
        },
        //调库原因
        getTransferReason(ctx) {
            api.getDictionary('transfer_reason').then(res => {
                ctx.commit('getTransferReason', res.data.data)
            })
        },
        //审核状态
        getReviewStatus(ctx) {
            api.getDictionary('review_status').then(res => {
                ctx.commit('getReviewStatus', res.data.data)
            })
        },
        //付款状态
        getPaymentStatus(ctx) {
            api.getDictionary('payment_status').then(res => {
                ctx.commit('getPaymentStatus', res.data.data)
            })
        },
        //出货类型
        getAllShipType(ctx) {
            api.getAllShipType().then(res => {
                ctx.commit('getAllShipType', res.data.data)
            })
        },
        //配送方式
        getAllShipping(ctx) {
            api.getAllShipping().then(res => {
                ctx.commit('getAllShipping', res.data.data)
            })
        },
        //付款方式
        getAllPayment(ctx) {
            api.getAllPayment().then(res => {
                ctx.commit('getAllPayment', res.data.data)
            })
        },
        //配送线路
        getAllLine(ctx) {
            api.getLine().then(res => {
                ctx.commit('getAllLine', res.data.data)
            })
        },
        //结算方式
        getSettlement(ctx){
            api.getExsettle().then(res => {
                ctx.commit('getSettlement',res.data.data)
            })
        },
        //发票类型
        getInvoiceType(ctx){
            api.getInvoiceex().then(res => {
                ctx.commit('getInvoiceType',res.data.data)
            })
        },
        // 单据级别
        getLevelOrder(ctx){
            api.getOrderLevel().then(res => {
                ctx.commit('getLevelOrder',res.data.data)
            })
        },

    }

}
