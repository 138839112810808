//自定义指令
import Vue from "vue";

const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents')
  e.initEvent(type, true, true)
  el.dispatchEvent(e)
}

Vue.directive('input-number', {
  bind(el, binding, vNode) {
    let elInput = el.querySelector('.el-input__inner')
    elInput.addEventListener('input', () => {
      let val = elInput.value
      let newVal = val.replace(/[^\d^]+/g, '')
      if (val !== newVal) {
        vNode.componentInstance.$emit('input', newVal)
      }
    }, false)
  }
})

Vue.directive('input-decimal', {
  bind(el, binding, vNode) {
    let elInput = el.querySelector('.el-input__inner')
    elInput.addEventListener('input', () => {
      let val = elInput.value
      let newVal = val.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')
      if (val !== newVal) {
        vNode.componentInstance.$emit('input', newVal)
      }
    }, false)
  }
})

//折扣输入框
Vue.directive('input-discount', {
  bind(el, binding, vNode) {
    let elInput = el.querySelector('.el-input__inner')
    elInput.addEventListener('input', () => {
      let val = elInput.value
      let newVal = val.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')

      if (Number(newVal) > 10) {
        elInput.value = 10
      } else if (val !== newVal) {
        elInput.value = newVal
      }

    }, false)
  }
})

//table懒加载
Vue.directive('load-more', {
  bind(el, binding, vNode) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function () {
      let sign = 10
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        binding.value()
      }
    })
  }
})


//el-table 自动高度
const doResize = (el, binding, VNode) => {
  const {componentInstance: $table} = VNode

  const {value} = binding

  if (!$table.height) {
    throw new Error(`el-$table must set the height. Such as height='100px'`)
  }
  const bottomOffset = (value && value.bottomOffset) || 120
  const height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset
  $table.layout.setHeight(height)
  $table.doLayout()
}
let resizeInterval
Vue.directive('table-auto-height', {
  bind(el, binding, VNode) {
    el.resizeListener = () => {
      doResize(el, binding, VNode)
    }
    setTimeout(() => {
      doResize(el, binding, VNode)
      resizeInterval = setInterval(el.resizeListener, 500)
    })
  },
  unbind(el) {
    clearInterval(resizeInterval)
  }
})

//防止重复点击
Vue.directive('prevent-re-click', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
})
