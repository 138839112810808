const TokenKey = 'x-access-token'

export function getToken () {
  let localToken = window.localStorage.getItem(TokenKey)
  let sessionToken = window.sessionStorage.getItem(TokenKey)

  return localToken || sessionToken
}

export function setToken (token,isAuto) {
  removeToken()
 if(isAuto){
   //自动登录
   window.localStorage.setItem(TokenKey,token)
 }else {
   //不自动登录
   window.sessionStorage.setItem(TokenKey,token)
 }
}

export function removeToken () {
  window.localStorage.removeItem(TokenKey)
  window.sessionStorage.removeItem(TokenKey)
}
