/*
    系统设置路由
 */
export default [
  {
    path: '/system',
    meta: {
      title: '系统管理'
    },
    component: () => import('@/layout/Main'),
    hidden: false,
    children: [
      { // 日志管理
        path: '/log',
        name: 'log',
        meta: {
          title: '日志管理'
        },
        hidden: false,
        component: () => import('@/views/system/log/logManger.vue')
      },
      { // 字典管理
        path: '/dict',
        name: 'dict',
        meta: {
          title: '字典管理'
        },
        hidden: false,
        component: () => import('@/views/system/dict/dict.vue')
      },
      { // 部门管理
        path: '/dept',
        name: 'dept',
        meta: {
          title: '数据权限'
        },
        hidden: true,
        component: () => import('@/views/system/dept/dept.vue')
      },
      {
        path: '/organizationManage',
        name: 'organizationManage',
        meta: {
          title: '组织管理'
        },
        hidden: true,
        component: () => import('@/views/system/organizationManage/index.vue')
      },
      {
        path: '/departmentManage',
        name: 'departmentManage',
        meta: {
          title: '部门管理'
        },
        hidden: true,
        component: () => import('@/views/system/departmentManage/index.vue')
      },
      {
        path: '/schoolManage',
        name: 'schoolManage',
        meta: {
          title: '学校管理'
        },
        hidden: true,
        component: () => import('@/views/system/schoolManage/index.vue')
      },
      {
        path: '/roleManage',
        name: 'roleManage',
        meta: {
          title: '角色管理'
        },
        hidden: true,
        component: () => import('@/views/system/roleManage/index.vue')
      },
      {
        path: '/rolePermission',
        name: 'rolePermission',
        meta: {
          title: '角色权限'
        },
        hidden: true,
        component: () => import('@/views/system/roleManage/rolePermission.vue')
      },
      {
        path: '/employeeManage',
        name: 'employeeManage',
        meta: {
          title: '员工管理'
        },
        hidden: true,
        component: () => import('@/views/system/employeeManage/index.vue')
      },
      {
        path: '/employeeInsert',
        name: 'employeeInsert',
        meta: {
          title: '新增员工'
        },
        hidden: true,
        component: () => import('@/views/system/employeeManage/employeeInsert.vue')
      },
      {
        path: '/menuManage',
        name: 'menuManage',
        meta: {
          title: '菜单管理'
        },
        hidden: true,
        component: () => import('@/views/system/menuManage/index.vue')
      },
      {
        path: '/userAgreement',
        name: 'userAgreement',
        meta: {
          title: '用户协议'
        },
        hidden: false,
        component: () => import('@/views/system/userAgreement/index.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: '关于我们'
        },
        hidden: false,
        component: () => import('@/views/system/aboutUs/index.vue')
      },
      {
        path: '/serviceSwitch',
        name: 'serviceSwitch',
        meta: {
          title: '服务开关'
        },
        hidden: false,
        component: () => import('@/views/system/serviceSwitch/index.vue')
      }
    ]
  }
];
