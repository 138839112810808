export default [
  {
    path: '/teachingProgram',
    name: 'teachingProgram',
    meta: {
      title: '教学计划'
    },
    hidden: false,
    component: () => import('@/layout/Main'),
    children: [
      {
        path: '/dailyProcessList',
        name: 'dailyProcessList',
        meta: {
          title: '一日流程列表'
        },
        hidden: false,
        component: () => import('@/views/teachingProgram/dailyProcessList/index.vue'),
      },
      {
        path: '/dailyProcessList/insertTemplate/:id',
        name: 'insertTemplate',
        meta: {
          title: '新建模版'
        },
        hidden: true,
        component: () => import('@/views/teachingProgram/dailyProcessList/insertTemplate.vue'),
      },
      {
        path: '/weeklyPlanTemplate',
        name: 'weeklyPlanTemplate',
        meta: {
          title: '周计划模版'
        },
        hidden: false,
        component: () => import('@/views/teachingProgram/weeklyPlanTemplate/index.vue'),
      },
      {
        path: '/TeachingPlanSetting/:id',
        name: 'TeachingPlanSetting',
        meta: {
          title: '周计划设置'
        },
        hidden: true,
        component: () => import('@/views/teachingProgram/weeklyPlanTemplate/TeachingPlanSetting.vue'),
      },
      {
        path: '/teachingProgram',
        name: 'teachingProgram',
        meta: {
          title: '周计划'
        },
        hidden: false,
        component: () => import('@/views/teachingProgram/index.vue'),
      },
    ]
  }
];
