import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'
import './assets/theme/index.css'
import './utils/validate'

import {api,url} from "./http/http.api";

import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

Vue.prototype.$api = api
Vue.prototype.$url = url
Vue.prototype.$bus = new Vue()
ElementUi.Dialog.props.top.default='5vh';
Vue.use(ElementUi)
// Vue.use(VeeValidate)
Vue.config.productionTip = false
Vue.use(Avue);

import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor);
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import './utils/directive'

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
