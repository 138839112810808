import axios from "axios";
import errorCode from "@/utils/errorCode";
import { Message } from "element-ui";
import { MessageBox } from "element-ui";
import router from "@/router";
import { getToken, setToken, removeToken } from "@/utils/auth";
import watermark from "@/utils/watermark";
import store from "@/store";

console.log(process.env);
let timeOutObject = 0;
const http = axios.create({
  baseURL: process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL,
  // timeout: 20000,
});

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // Authorization
    if (!config.headers["token"] && config.url != "/api/edulogin/login") {
      config.headers["token"] = getToken();
    }
    if (config.url.indexOf("wopi") != -1) {
      config.baseURL = process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_VIEW_URL + "/viewApi" : process.env.VUE_APP_LOCAL_VIEW_URL;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    console.log("响应拦截器：", response);
    console.log(router);
    let routerName = router.app._route.name;

    // 对响应数据做点什么
    if (response.data.code && response.data.code == 1) {
      console.log(response.data.msg);
      // Message.error(response.data.msg)
    } else if (response.data.code == 2) {
      if (timeOutObject !== 1) {
        timeOutObject++;
        localStorage.removeItem("userInfo");
        removeToken("x-access-token");
        watermark.set("");
        router.push("/login");
        return response;
      } else if (timeOutObject === 1) {
        timeOutObject = 0;
        return false;
      }
    } else if (response.data.code == 20 && routerName != "Login") {
      JudgeReplace();
      return false;
    } else {
      if (response.headers.token) {
        let isAutoLogin = localStorage.getItem("x-access-token");
        let isNoAutoLogin = sessionStorage.getItem("x-access-token");

        if (isAutoLogin && !isNoAutoLogin) {
          setToken(response.headers.token, true);
        } else if (!isAutoLogin && isNoAutoLogin) {
          setToken(response.headers.token, false);
        }
      }
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    const message = error.response.data.msg || errorCode[error.response.status] || errorCode["default"];

    if (error.response.status === 401) {
      router.push("/login");
      return Promise.reject(error);
    } else if (response.data.code == 20 && routerName != "Login") {
      JudgeReplace();
      return false;
    } else {
      Message.error(message);
      return Promise.reject(error);
    }
  }
);

function JudgeReplace() {
  MessageBox.confirm("您的账号已在其他设备登录，如果不是您本人操作，建议您修改密码~ \n 是否前去修改密码?", "提示", {
    confirmButtonText: "是",
    cancelButtonText: "否",
    closeOnClickModal: false,
    closeOnPressEscape: false,
    closeOnHashChange: false,
    showClose: false,
    type: "error",
  })
    .then(() => {
      localStorage.removeItem("userInfo");
      removeToken("x-access-token");
      watermark.set("");
      router.push("/login");
      setTimeout(() => {
        store.commit("setModifyPwd", true);
      }, 1000);
    })
    .catch(() => {
      localStorage.removeItem("userInfo");
      removeToken("x-access-token");
      watermark.set("");
      router.push("/login");
    });
}

export default http;
