import store from '@/store'

export default ()=>{
    store.dispatch('common/getFeedbackTypeList').then() //获取意见反馈类型
    store.dispatch('common/getCourseTypeList').then()//获取课程分类
    store.dispatch('common/getCourseFieldList').then() //获取课程领域
    store.dispatch('common/getCourseLevelList').then() //获取课程等级
    store.dispatch('common/getAgeScopeList').then() //获取年龄阶段
    store.dispatch('common/getActivityTypeList').then() //获取活动分类
    store.dispatch('common/getCourseThemeList').then() //获取课程专题
    store.dispatch('common/getCourseVersionList').then() //获取课程版本
    // store.dispatch('common/getSearchCourseLevelList').then() //获取课程资源筛选-课程等级
    // store.dispatch('common/getSearchAgeScopeList').then() //获取课程资源筛选-年龄阶段
    // store.dispatch('common/getSubmitTypeList').then() //获取提交方式分类
}
