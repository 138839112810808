import http from "./http.interceptors";
import axios from "axios";
import {systemApi} from "./modules/http.api.system";
import { courseApi } from "./modules/http.api.course";
import { getToken } from "@/utils/auth";
import teachingProgram from './modules/http.api.teachingProgram';

const url = {
    codeUrl: '/code?randomStr=',
    getUploadUrl: '/sszagoods/goodsImg/getUploadUrl',
}

for (let key in url) {
    url[key] = process.env.VUE_APP_LOCAL_URL + url[key]
}

// url.imgUrl='http://10.86.3.202:8080'
// url.imgUrl=''
url.imgUrl=process.env.VUE_APP_IMG_URL

const api = {
    ...systemApi,
    ...courseApi,
    ...teachingProgram,
    getDictionary: params => http.get('/api/course/dict/list', { params }),
    getSearchResourseDictionary: params => http.get('/api/course/dict/listByCourseScopeLevel', { params }),
    getSearchResourseVersions: params => http.get('/api/course/dict/listByCourseVersion', { params }),
    getSearchResourseTheme: params => http.get('/api/course/dict/listByCourseTheme', { params }),
    getResourseDictionary: params => http.get('/api/techdict/list', { params }),
    //获取菜单权限
    getMenu: () => http.get('/api/techauth/menuAuth'),
    //获取用户信息
    getUerInfo: () => http.get('/admin/user/info'),
    //用户名登录
    loginByUsername: params => http.post('/api/techloginuser/login', params),
    // 数据权限
    getDept: () => http.get('/api/edudept/deptList'),
    //权限修改
    eduauthData: params => http.post('/api/eduauthdata', params),
    // 修改密码
    userEdit: params => http.post('/api/techloginuser/update/pwd', params),
    // 重置密码
    resetPwd: params => http.post('/api/techloginuser/resetPwd', params),
    // 获取验证码
    getVerificationCode: params => http.get('/api/techloginuser/verificationCode', {params}),
    //获取校验码
    getAuthCode: params => http.get('/api/techloginuser/getCode', {params}),
    ////上传方法
    importImgFile: (url,file) => {
        let forms = new FormData()
        let configs = {
            headers:{'Content-Type':'application/x-www-form-urlencoded'},
        };
        forms.append('file',file)

      return http.post(url,forms,configs)
    }
}

export { api, url }
